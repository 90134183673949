.carousel {
    width: 100vw; /* Full width of the viewport */
    max-width: 100vw;
    height: 100%;
    overflow: hidden;
    margin: auto;
  }

  .carousel-inner {
    width: 100%; /* Full width of the screen */
    height: 100%; /* Fixed height for the videos */
    overflow: hidden; /* Ensures sides of the videos are cropped */
    position: relative;
  }

  .video {
    width: 100%; /* Full width of the screen */
    height: 100%; /* Fixed height for the videos */
    overflow: hidden; /* Ensures sides of the videos are cropped */
  }


  .carousel-caption{
    bottom: 20%;
  }

.carousel .carousel-control-prev-icon {
  visibility: hidden;
}
.carousel:hover .carousel-control-prev-icon {
  visibility: visible;
}

.carousel .carousel-control-next-icon {
  visibility: hidden;
}
.carousel:hover .carousel-control-next-icon {
  visibility: visible;
}